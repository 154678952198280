import React from 'react';
import { graphql } from 'gatsby';

import Hero from '../components/Hero';
import Layout from '../components/Layout/Layout';
import { Container } from '../components/Grid';
import Band from '../components/Band/Band';
import Typography from '../components/Typography';
import Contact from '../components/Contact/Contact';
import PageHeader from '../components/PageHeader/PageHeader';
import SEO from '../components/SEO';
import Tiles from '../components/Tiles/Tiles';

export default ({ data }) => (
    <Layout>
        <SEO
            title="Recruitment industry areas"
            description="We partner with innovative organisations. Our main industry areas cover Automotive &amp; Mobility, Smart Home, Consumer Electronics &amp; IoT, Industry &amp; Manufacturing, Health &amp; Wellness, Fintech"
        />
        <Hero
            title="Black Dot Industry Areas"
            mobileImage={data.mobileImage}
            desktopImage={data.desktopImage}
            logoColor="white"
            logoPosition="top-right"
        />
        <Band size="md">
            <Container>
                <Typography color="purple">
                    <PageHeader>
                        We help organisations get the best out of their talent and people make the most of their
                        opportunities
                    </PageHeader>
                    <p>
                        We believe in partnering with innovative organisations and providing opportunities that improve
                        people’s lives. This is our purpose.
                    </p>
                    <p>Our main industry areas cover:</p>
                    <p>
                        Automotive &amp; Mobility | Smart Home | Consumer Electronics &amp; IoT | Industry &amp;
                        Manufacturing | Health &amp; Wellness | Fintech
                    </p>
                </Typography>
            </Container>
        </Band>
        <Band>
            <Tiles />
        </Band>
        <Band size="md">
            <Container>
                <Contact heading="Let's connect" />
            </Container>
        </Band>
    </Layout>
);

export const query = graphql`
    query WherePageQuery {
        desktopImage: file(relativePath: { eq: "unsplash-zLJMOjUr61k.jpg" }) {
            ...HeroBackgroundImage_Desktop
        }
        mobileImage: file(relativePath: { eq: "unsplash-sm-zLJMOjUr61k.jpg" }) {
            ...HeroBackgroundImage_Mobile
        }
    }
`;
